import { getTraceId } from '@utils/trace-utils';
import axios from 'axios';
import { getConfig } from 'bernie-config';
import { serializeError } from 'serialize-error';
import { ERROR, JSON_HEADERS, TRACE_ID } from 'src/constants';

const SERVICE_NAME = 'USER_PROFILE_SERVICE';

export interface IUserProfile {
  userId: string;
  status: string;
  data: IProfileData;
  metadata: {
    id: string;
    revision: number;
    createdBy: string;
    createdTimestamp: string;
    lastModifiedBy: string;
    lastModifiedTimestamp: string;
  };
}

interface IProfileData {
  'v1/account-holder-info': {
    shared: {
      basic_info: {
        first_name: string;
        last_name: string;
      };
      contact_info: {
        primary_email: {
          email: string;
        };
      };
    };
  };
}

export interface IProfileDataShared {
  data: {
    basic_info: {
      first_name: string;
      last_name: string;
    };
    contact_info: {
      primary_email: {
        email: string;
      };
    };
  };
}

const getUserProfile: (request, principalToken, userId) => Promise<IProfileDataShared> = async (
  request,
  principalToken,
  userId,
) => {
  const traceId = getTraceId(request);
  request.log([SERVICE_NAME, 'advertiser-portal-pwa.info.getUserProfile', traceId], {
    message: `Getting user profile data for user ${userId}`,
  });

  if (!principalToken || !userId) {
    request.log([ERROR, SERVICE_NAME, 'advertiser-portal-pwa.error.getUserProfile', traceId], {
      userId: userId,
      message: 'No user or token available',
    });
    throw new Error('Unauthorized');
  }

  const {
    services: { universalProfileService },
  } = getConfig();
  const { protocol, hostname } = universalProfileService;

  try {
    const response = await axios({
      method: 'GET',
      url: `${protocol}//${hostname}/v1/profile/${userId}/v1/account-holder-info/shared`,
      signal: AbortSignal.timeout(5000),
      headers: {
        ...JSON_HEADERS,
        [TRACE_ID]: traceId,
        Authorization: `EGToken Principal-JWT=${principalToken}`,
      },
    });

    request.log([SERVICE_NAME, 'advertiser-portal-pwa.info.getUserProfile', traceId], {
      message: `Getting user profile data for user ${userId} complete`,
      data: response.data,
    });

    return response.data || {};
  } catch (error) {
    request.log([ERROR, SERVICE_NAME, 'advertiser-portal-pwa.error.getUserProfile', traceId], {
      error: serializeError(error),
    });
    return null;
  }
};

/************************Terms and Conditions****************************/
const getUserTermsConditionsAcceptance: (request, principalToken, userId) => Promise<IUserProfile & Error> = async (
  request,
  accessToken,
  userId,
) => {
  const loggingMessage = `Getting Policies Extension data for user ${userId}`;

  request.log([SERVICE_NAME, 'advertiser-portal-pwa.info.updatePoliciesExtension'], {
    message: loggingMessage,
  });

  if (!accessToken || !userId) {
    request.log([ERROR, SERVICE_NAME, 'advertiser-portal-pwa.error.getUserTermsConditionsAcceptance'], {
      userId: userId,
      message: 'No user or token available',
    });
    throw new Error('Unauthorized');
  }

  const {
    services: { universalProfileService },
  } = getConfig();
  const { protocol, hostname } = universalProfileService;

  try {
    const response = await axios({
      method: 'GET',
      url: `${protocol}//${hostname}/v1/profile/${userId}/v1/terms-and-conditions-policy`,
      signal: AbortSignal.timeout(5000),
      headers: {
        ...JSON_HEADERS,
        Authorization: `Bearer ${accessToken}`,
      },
    });

    request.log([SERVICE_NAME, 'advertiser-portal-pwa.info.getUserTermsConditionsAcceptance'], {
      message: loggingMessage + ' complete',
      data: response.data,
    });

    return response.data || {};
  } catch (error) {
    request.log([ERROR, SERVICE_NAME, 'advertiser-portal-pwa.error.getUserTermsConditionsAcceptance'], {
      error: serializeError(error),
    });
    return null;
  }
};

export const updateTermsConditionsPoliciesExtension: (
  accessToken,
  userIdInput,
  utcTimestampInput,
  productInput,
  typeInput,
  versionInput,
) => Promise<IUserProfile & Error> = async (
  accessToken,
  userIdInput,
  utcTimestampInput,
  productInput,
  typeInput,
  versionInput,
) => {
  const loggingMessage = `Updating Policies Extension data for user ${userIdInput}`;

  console.log([SERVICE_NAME, 'advertiser-portal-pwa.info.updatePoliciesExtension'], {
    message: loggingMessage,
  });

  if (!accessToken || !userIdInput) {
    console.log([ERROR, SERVICE_NAME, 'advertiser-portal-pwa.error.updatePoliciesExtension'], {
      userId: userIdInput,
      message: 'No user or token available',
    });
    throw new Error('Unauthorized');
  }

  const {
    services: { universalProfileService },
  } = getConfig();
  const { protocol, hostname } = universalProfileService;

  const data = {
    data: {
      timestamp: utcTimestampInput,
      version: versionInput,
      additionalProp1: { type: 'Direct Advertiser' },
    },
    element_name: productInput,
  };

  try {
    const response = await axios({
      method: 'POST',
      url: `${protocol}//${hostname}/v1/profile/${userIdInput}/v1/terms-and-conditions-policy/`,
      signal: AbortSignal.timeout(5000),
      headers: {
        ...JSON_HEADERS,
        Authorization: `Bearer ${accessToken}`,
      },
      data,
    });

    console.log([SERVICE_NAME, 'advertiser-portal-pwa.info.updatePoliciesExtension'], {
      message: loggingMessage + ' complete',
      data: response.data,
    });

    return response.data || {};
  } catch (error) {
    console.log([ERROR, SERVICE_NAME, 'advertiser-portal-pwa.error.updatePoliciesExtension'], {
      error: serializeError(error),
    });
    return null;
  }
};

export const patchTermsConditionsPoliciesExtension: (
  accessToken,
  userIdInput,
  pathInput,
  valueInput,
  elementNameInput,
) => Promise<IUserProfile & Error> = async (accessToken, userIdInput, pathInput, valueInput, elementNameInput) => {
  const loggingMessage = `Patching Policies Extension data for user ${userIdInput}`;

  console.log([SERVICE_NAME, 'advertiser-portal-pwa.info.patchPoliciesExtension'], {
    message: loggingMessage,
  });

  if (!accessToken || !userIdInput) {
    console.log([ERROR, SERVICE_NAME, 'advertiser-portal-pwa.error.patchPoliciesExtension'], {
      userId: userIdInput,
      message: 'No user or token available',
    });
    throw new Error('Unauthorized');
  }

  const {
    services: { universalProfileService },
  } = getConfig();
  const { protocol, hostname } = universalProfileService;

  const data = [
    {
      op: 'replace',
      path: pathInput,
      value: valueInput,
    },
  ];

  try {
    const response = await axios({
      method: 'PATCH',
      url: `${protocol}//${hostname}/v1/profile/${userIdInput}/v1/terms-and-conditions-policy/${elementNameInput}`,
      signal: AbortSignal.timeout(5000),
      headers: {
        ...JSON_HEADERS,
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json-patch+json',
      },
      data,
    });

    console.log([SERVICE_NAME, 'advertiser-portal-pwa.info.patchPoliciesExtension'], {
      message: loggingMessage + ' complete',
      data: response.data,
    });

    return response.data || {};
  } catch (error) {
    console.log([ERROR, SERVICE_NAME, 'advertiser-portal-pwa.error.patchPoliciesExtension'], {
      error: serializeError(error),
    });
    return null;
  }
};

/************************Language Preferences****************************/
export const getUserLanguagePreference: (request, principalToken, userId) => Promise<IUserProfile & Error> = async (
  request,
  accessToken,
  userId,
) => {
  const loggingMessage = `Getting Policies Extension data for user ${userId}`;

  request.log([SERVICE_NAME, 'advertiser-portal-pwa.info.getUserLanguagePreference'], {
    message: loggingMessage,
  });

  if (!accessToken || !userId) {
    request.log([ERROR, SERVICE_NAME, 'advertiser-portal-pwa.error.getUserLanguagePreference'], {
      userId: userId,
      message: 'No user or token available',
    });
    throw new Error('Unauthorized');
  }

  const {
    services: { universalProfileService },
  } = getConfig();
  const { protocol, hostname } = universalProfileService;

  try {
    const response = await axios({
      method: 'GET',
      url: `${protocol}//${hostname}/v1/profile/${userId}/v1/general-preferences`,
      signal: AbortSignal.timeout(5000),
      headers: {
        ...JSON_HEADERS,
        Authorization: `Bearer ${accessToken}`,
      },
    });

    request.log([SERVICE_NAME, 'advertiser-portal-pwa.info.getUserLanguagePreference'], {
      message: loggingMessage + ' complete',
      data: response.data,
    });

    return response.data || {};
  } catch (error) {
    request.log([ERROR, SERVICE_NAME, 'advertiser-portal-pwa.error.getUserLanguagePreference'], {
      error: serializeError(error),
    });
    return null;
  }
};

export const updateLanguagePreferenceExtension: (
  accessToken,
  userIdInput,
  elementNameInput,
  preferredLanguageInput,
) => Promise<IUserProfile & Error> = async (accessToken, userIdInput, elementNameInput, preferredLanguageInput) => {
  const loggingMessage = `Updating Policies Extension data for user ${userIdInput}`;

  console.log([SERVICE_NAME, 'advertiser-portal-pwa.info.updatePoliciesExtension'], {
    message: loggingMessage,
  });

  if (!accessToken || !userIdInput) {
    console.log([ERROR, SERVICE_NAME, 'advertiser-portal-pwa.error.updatePoliciesExtension'], {
      userId: userIdInput,
      message: 'No user or token available',
    });
    throw new Error('Unauthorized');
  }

  const {
    services: { universalProfileService },
  } = getConfig();
  const { protocol, hostname } = universalProfileService;

  const data = {
    data: {
      preferred_language: preferredLanguageInput,
      preferred_country: '',
      preffered_currency: '',
    },
    element_name: elementNameInput,
  };

  try {
    const response = await axios({
      method: 'POST',
      url: `${protocol}//${hostname}/v1/profile/${userIdInput}/v1/general-preferences`,
      signal: AbortSignal.timeout(5000),
      headers: {
        ...JSON_HEADERS,
        Authorization: `Bearer ${accessToken}`,
      },
      data,
    });

    console.log([SERVICE_NAME, 'advertiser-portal-pwa.info.updateLanguagePreferenceExtension'], {
      message: loggingMessage + ' complete',
      data: response.data,
    });

    return response.data || {};
  } catch (error) {
    console.log([ERROR, SERVICE_NAME, 'advertiser-portal-pwa.error.updateLanguagePreferenceExtension'], {
      error: serializeError(error),
    });
    return null;
  }
};

export const patchLanguagePreferenceExtension: (
  accessToken,
  userIdInput,
  pathInput,
  valueInput,
  elementNameInput,
) => Promise<IUserProfile & Error> = async (accessToken, userIdInput, pathInput, valueInput, elementNameInput) => {
  const loggingMessage = `Patching Policies Extension data for user ${userIdInput}`;

  console.log([SERVICE_NAME, 'advertiser-portal-pwa.info.patchLanguagePreferenceExtension'], {
    message: loggingMessage,
  });

  if (!accessToken || !userIdInput) {
    console.log([ERROR, SERVICE_NAME, 'advertiser-portal-pwa.error.patchLanguagePreferenceExtension'], {
      userId: userIdInput,
      message: 'No user or token available',
    });
    throw new Error('Unauthorized');
  }

  const {
    services: { universalProfileService },
  } = getConfig();
  const { protocol, hostname } = universalProfileService;

  const data = [
    {
      op: 'replace',
      path: pathInput,
      value: valueInput,
    },
  ];

  try {
    const response = await axios({
      method: 'PATCH',
      url: `${protocol}//${hostname}/v1/profile/${userIdInput}/v1/general-preferences/${elementNameInput}`,
      signal: AbortSignal.timeout(5000),
      headers: {
        ...JSON_HEADERS,
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json-patch+json',
      },
      data,
    });

    console.log([SERVICE_NAME, 'advertiser-portal-pwa.info.patchLanguagePreferenceExtension'], {
      message: loggingMessage + ' complete',
      data: response.data,
    });

    return response.data || {};
  } catch (error) {
    console.log([ERROR, SERVICE_NAME, 'advertiser-portal-pwa.error.patchLanguagePreferenceExtension'], {
      error: serializeError(error),
    });
    return null;
  }
};

export { getUserProfile, getUserTermsConditionsAcceptance };

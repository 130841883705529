import { SerializedData } from 'bernie-core';
import { Logger, NOOP_LOGGER } from 'bernie-logger';
import { Store } from 'bernie-plugin-mobx';
import { makeObservable } from 'mobx';
import { action, observable } from 'mobx';

export interface IBanner {
  headline: string;
  message: string;
  type: 'error' | 'success';
  regex: string;
  buttonText?: string;
}

export class BannerStore extends Store {
  @observable banners: Array<IBanner> = [];
  config;
  constructor(config, logger: Logger = NOOP_LOGGER) {
    super({}, logger);
    this.config = config;
    makeObservable(this);
  }

  hydrate(data: SerializedData): void {
    Object.assign(this, data);
  }

  @action
  addMessage(headline: string, message: string, type: 'error' | 'success', regex: string, buttonText: string) {
    this.banners.push({ headline, message, type, regex, buttonText });
  }

  get errorMessages() {
    return this.banners.filter((message) => message.type === 'error');
  }

  get successMessages() {
    return this.banners.filter((message) => message.type === 'success');
  }

  @action
  clearMessages() {
    this.banners = [];
  }

  clearErrorMessages() {
    this.banners = this.banners.filter((message) => message.type !== 'error');
  }

  clearSuccessMessages() {
    this.banners = this.banners.filter((message) => message.type !== 'success');
  }
}

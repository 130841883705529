// Feature flags defined
// Result is only used for mockValues

interface IDYFF_FLAGS {
  featureId: string;
  result: boolean;
}

export const DYFF_FLAGS: IDYFF_FLAGS[] = [
  {
    featureId: 'BERNIE_TEST',
    result: true,
  },
  {
    featureId: 'EMAIL_NOTIFICATIONS',
    result: true,
  },
  {
    featureId: 'TAX_PROFILE_STEP',
    result: false,
  },
  {
    featureId: 'GA_ACCOUNT_STRUCTURE_IMAGE',
    result: true,
  },
  {
    featureId: 'GA_PAYMENT_STRUCTURE_IMAGE',
    result: true,
  },
  {
    featureId: 'SHARED_MULTIPLE_PAYMENT_PROFILES',
    result: true,
  },
  {
    featureId: 'SHOW_PARTNER_ACCOUNT_NAME',
    result: true,
  },
];

import { SystemEvent, SystemEventLevel } from '@shared-ui/logger-context';

export const CDN_URL = 'https://c.travel-assets.com/advertiser-portal-pwa/';

export const LOG_EVENTS = {
  INFO_EVENT: new SystemEvent(SystemEventLevel.INFO, 'INFO_EVENT'),
  WARNING_EVENT: new SystemEvent(SystemEventLevel.WARN, 'WARNING_EVENT'),
  ERROR_EVENT: new SystemEvent(SystemEventLevel.ERROR, 'ERROR_EVENT'),
  CRITICAL_EVENT: new SystemEvent(SystemEventLevel.CRITICAL, 'CRITICAL_EVENT'),
};

export const TRACE_ID = 'TRACE-ID';

export const ERROR = 'ERROR';

export const JSON_HEADERS = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const TERMS_CONDITIONS_PROFILE_EXTENSION_PRODUCT = 'sponsored-content-campaign-management';
export const LANGUAGE_PREFERENCES_PROFILE_EXTENSION_PRODUCT = 'sponsored-content-campaign-management';
export const DIRECT_ADVERTISER = 'Direct advertiser';

export const LANGUAGES = [
  { label: 'English (EN)', code: 'EN', locale: 'en_US' },
  { label: 'Español (ES)', code: 'ES', locale: 'es_ES' },
  { label: 'Français (FR)', code: 'FR', locale: 'fr_FR' },
  { label: 'Deutsch (DE)', code: 'DE', locale: 'de_DE' },
  { label: 'Italiano (IT)', code: 'IT', locale: 'it_IT' },
  { label: '日本語 (JP)', code: 'JP', locale: 'ja_JP' },
  { label: '한국어 (KR)', code: 'KR', locale: 'ko_KR' },
];

export const SPONSORED_CONTENT_CAMPAIGN_PRODUCT_ID = 'sponsored-content-campaign-management';
export const UMP_PREFERENCE_API_TENANT_NAME = 'MESO_CAP';

export const HELP_SUPPORT_MAX_TOTAL_SIZE_MB = 25 * 1024 * 1024; // 25MB in bytes
export const MAX_NUMBER_OF_ATTACHMENTS = 5;

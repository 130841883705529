module.exports = {
  Query: {
    fields: {
      userManagement: {
        merge: true,
      },
      campaignWizard: {
        merge: true,
      },
    },
  },
  CampaignWizardNavigation: {
    keyFields: [],
  },
  SideBarCampaign: {
    keyFields: ['id', 'productId'],
  },
};

import { AuthController } from '@controllers/auth-controller';
import { ADVERTISER_EMAIL_SETTINGS, PARTNER_ROLES } from '@utils/controller-utils';
import { codeSplit } from 'bernie-core';

export class AdvertiserEmailSettingsController extends AuthController {
  public pageId = ADVERTISER_EMAIL_SETTINGS;
  public path = '/notification-settings';
  public routeName = 'notification-settings';
  public bundles = [];
  public pageTitle = 'controllers.notificationSettings';
  public isPartnerView = true;
  public accessRoles = [
    PARTNER_ROLES.ADVERTISER_ADMIN,
    PARTNER_ROLES.ADVERTISER_USER,
    PARTNER_ROLES.ADVERTISER_VIEWER,
    PARTNER_ROLES.ADMIN, //deprecated, to be removed
  ];

  public component = codeSplit(
    () => import(/* webpackChunkName: "advertiser-email-settings" */ '../../views/partner/advertiser-email-settings'),
  );
  public exact = true;
}
